<messages>["./OverallResults"]</messages>
<template>
  <base-layout :mw="$vuetify.breakpoint.lgAndDown ? '2' : '3'">
    <!-- filters -->
    <v-col cols="12" lg="8" xl="4">
      <!-- search filter -->
      <overall-results-filter
        :value="filter || undefined"
        :visible="showFilter"
        :search-disabled="isLoading"
        @input="onFilterChange"
        @visibilityChanged="onFilterVisibilityChange"/>
    </v-col>

    <!-- invoice list -->
    <v-col cols="12" xl="8">
      <v-alert
        v-model="showResultAlert"
        v-t="'general.info.searchResults'"
        :value="true"
        type="info"
        color="primary"/>

      <v-card v-if="isResultTableVisible">
        <v-card-title primary-title>
          <div
            v-t="'list.title'"
            class="text-h5"/>
          <v-spacer/>
          <csv-download-btn :total-count="totalCount" :csv-download-url="csvDownloadUrl"/>
        </v-card-title>
        <v-card-text>
          <overall-results-table
            :results="resultList"
            :loading="isLoading"/>
        </v-card-text>
      </v-card>
    </v-col>
  </base-layout>
</template>

<script>
  import {mapActions} from 'vuex'

  import SearchPage from '@/app/core/mixins/SearchPage'
  import BaseLayout from '@/app/core/components/BaseLayout'

  import OverallResultsFilter from './components/OverallResultsFilter'
  import OverallResultsTable from './components/OverallResultsTable'
  import CsvDownloadBtn from '@/app/core/components/CsvDownloadBtn'

  export default {
    name: 'OverallResults',
    components: {
      CsvDownloadBtn,
      BaseLayout,
      OverallResultsFilter,
      OverallResultsTable
    },

    mixins: [SearchPage],

    data () {
      return {
        // this value is used by mixin for sending requests to the BE API
        OPERATION: 'internal-account/list',
        disablePagination: true
      }
    },

    methods: {

      ...mapActions ({
        fetchData: 'request/fetchData'
      }),
      // for the mixin to work
      getStoredSearchQuery () {
        return undefined
      },
      // for the mixin to work
      storeSearchQuery () {}
    }
  }
</script>
