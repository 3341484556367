var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('search-filter-wrapper',{attrs:{"type":"overallResults","quick-filter-items":_vm.quickFilterItems,"additional-quick-filter-items":_vm.additionalQuickFilterItems,"search-disabled":_vm.searchDisabled},on:{"deleteFilter":_vm.onDeleteFilter,"deleteAdditionalFilter":_vm.onDeleteAdditionalFilter,"reset":_vm.resetSearchFilter,"search":_vm.doSearch,"focus":_vm.focus},scopedSlots:_vm._u([{key:"basic",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('date-picker-field',{attrs:{"type":"month","error-messages":_vm.validationErrors (
            'filterParams.start', {
              required: 'general.required'
            }),"label":_vm.$t('label.start')},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('date-picker-field',{attrs:{"type":"month","error-messages":_vm.validationErrors (
            'filterParams.end', {
              required: 'general.required',
              beforeStart: 'label.beforeStart'
            }),"label":_vm.$t('label.end')},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c('v-col',[_c('v-row',_vm._l((_vm.quickfilter),function(btn){return _c('v-col',{key:btn.label,attrs:{"cols":"auto"}},[_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:(btn.label),expression:"btn.label"}],attrs:{"rounded":"","small":""},on:{"click":btn.method}})],1)}),1)],1),_c('v-col',[_c('v-switch',{attrs:{"label":_vm.$t('label.totalOnly')},model:{value:(_vm.filterParams.totalOnly),callback:function ($$v) {_vm.$set(_vm.filterParams, "totalOnly", $$v)},expression:"filterParams.totalOnly"}})],1)],1)]},proxy:true}]),model:{value:(_vm.filterVisibilityModel),callback:function ($$v) {_vm.filterVisibilityModel=$$v},expression:"filterVisibilityModel"}})}
var staticRenderFns = []

export { render, staticRenderFns }