<messages>["../OverallResults"]</messages>

<template>
  <search-filter-wrapper
    v-model="filterVisibilityModel"
    type="overallResults"
    :quick-filter-items="quickFilterItems"
    :additional-quick-filter-items="additionalQuickFilterItems"
    :search-disabled="searchDisabled"
    @deleteFilter="onDeleteFilter"
    @deleteAdditionalFilter="onDeleteAdditionalFilter"
    @reset="resetSearchFilter"
    @search="doSearch"
    @focus="focus">
    <!-- filter form -->

    <template #basic>
      <v-row>
        <v-col cols="12" sm="6">
          <date-picker-field
            v-model="startDate"
            type="month"
            :error-messages="validationErrors (
              'filterParams.start', {
                required: 'general.required'
              })"
            :label="$t('label.start')"/>
        </v-col>
        <v-col cols="12" sm="6">
          <date-picker-field
            v-model="endDate"
            type="month"
            :error-messages="validationErrors (
              'filterParams.end', {
                required: 'general.required',
                beforeStart: 'label.beforeStart'
              })"
            :label="$t('label.end')"/>
        </v-col>
        <v-col>
          <v-row>
            <v-col
              v-for="btn in quickfilter"
              :key="btn.label"
              cols="auto">
              <v-btn
                v-t="btn.label"
                rounded small
                @click="btn.method"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-switch
            v-model="filterParams.totalOnly"
            :label="$t('label.totalOnly')"/>
        </v-col>
      </v-row>
    </template>
  </search-filter-wrapper>
</template>

<script>

  import {required} from 'vuelidate/lib/validators'

  import SearchFilterWrapper
    from '@/app/core/components/Search/SearchFilterWrapper'

  import DatePickerField, {beforeCurrentMonth}
    from '@/app/core/components/DatePickerField'

  import InputHelper from '@/app/core/mixins/InputHelper'
  import QuickFilterMixin from '@/app/core/mixins/QuickFilterMixin'
  import SearchFilterMixinCreator from '@/app/core/mixins/SearchFilterCreator'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import {lastMonth, currentYear, lastYear} from './ReportWrapper'

  /**
   * default invoice search filter parameters
   * @type {Object}
   */
  export const defaultValue = () => ({
    totalOnly: false,
    start: '',
    end: ''
  })

  export default {
    name: 'OverallResultsFilter',

    components: {
      SearchFilterWrapper,
      DatePickerField
    },

    mixins: [
      SearchFilterMixinCreator (defaultValue),
      QuickFilterMixin,
      InputHelper,
      validationMixins
    ],

    data () {
      return {
        customHandlerKeys: [
          'start', 'end', 'totalOnly'
        ],
        quickfilter: [
          {
            label: 'quickfilter.lastMonth',
            method: this.dateSetter (lastMonth)
          },
          {
            label: 'quickfilter.currentYear',
            method: this.dateSetter (currentYear)
          },
          {
            label: 'quickfilter.lastYear',
            method: this.dateSetter (lastYear)
          }
        ]
      }
    },

    computed: {
      endDate: {
        get () {
          return this.formatDateShort (this.filterParams.end, true)
        },
        set (newVal) {
          const newDate = new Date (newVal).getTime ()
          this.filterParams.end = Number.isNaN (newDate) ? null : newDate
        }
      },
      startDate: {
        get () {
          return this.formatDateShort (this.filterParams.start, true)
        },
        set (newVal) {
          const newDate = new Date (newVal).getTime ()
          this.filterParams.start = Number.isNaN (newDate) ? null : newDate
        }
      }
    },

    validations () {
      return {
        filterParams: {
          end: {
            beforeStart (value) {
              return this.filterParams.start && value
                ? new Date (this.filterParams.start) <= new Date (value)
                : true
            },
            required
          },
          start: {
            required
          }
        }
      }
    },

    methods: {
      dateSetter (dateGetter) {
        return (val) => {
          const {start, end} = dateGetter ()
          this.filterParams.start = start.getTime ()
          this.filterParams.end = end.getTime ()
        }
      },

      validFrom (dateStr) {
        if (!this.filterParams.end) {
          return beforeCurrentMonth (dateStr)
        }

        const d = new Date (dateStr)
        const end = new Date (this.filterParams.end)

        return beforeCurrentMonth (dateStr) && d <= end
      },

      validTo (dateStr) {
        if (!this.filterParams.start) {
          return beforeCurrentMonth (dateStr)
        }

        const d = new Date (dateStr)
        const start = new Date (this.filterParams.start)

        return beforeCurrentMonth (dateStr) && d >= start
      },

      customQuickFilterHandler (prop, value, qFItems) {
        let text = value
        let label = prop

        switch (prop) {
          case 'start':
          case 'end':
            text = this.formatDateShort (value, true)
            label = this.$t (`label.${prop}`)
            break
          case 'totalOnly':
            text = ' '
            label = this.$t ('label.totalOnly')
        }

        qFItems.push ({
          prop,
          label,
          text
        })
      }
    }
  }
</script>
