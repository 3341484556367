<messages>["../OverallResults"]</messages>
<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <v-data-table
    class="elevation-1"
    :headers="headers"
    :items="results"
    disable-pagination
    hide-default-footer
    :loading="loading">
    <template #item="props">
      <tr>
        <td>
          {{ props.item.id }}
        </td>
        <td>
          {{ formatDateShort(props.item.periodStart) }}
        </td>
        <td>
          {{ formatDateShort(props.item.periodEnd) }}
        </td>
        <td>
          {{ props.item.type }}
        </td>
        <td class="text-right">
          {{ formatMoneyAmount (props.item.value, props.item.currency) }}
        </td>
      </tr>
    </template>
    <template #no-data>
      <search-table-no-data
        :is-loading="loading"
        :text="$t ('list.empty')"/>
    </template>
  </v-data-table>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import SearchTableNoData from '@/app/core/components/Search/SearchTableNoData'

  export default {
    name: 'OverallResultsTable',

    components: {
      SearchTableNoData
    },

    props: {
      totalCount: {
        type: Number,
        default: 0
      },
      results: {
        type: Array,
        default: () => []
      },
      loading: Boolean
    },

    computed: {
      headers () {
        const tvs = (text, value = undefined, sortable = false, align = 'left') => ({
          text,
          value,
          sortable,
          align
        })
        const headerItem = it => tvs (this.$t (`label.${it}`), it, true)

        return [
          headerItem ('id'),
          headerItem ('periodStart'),
          headerItem ('periodEnd'),
          headerItem ('type'),
          tvs (this.$t ('label.value'), 'value', true, 'right')
        ]
      }
    }
  }
</script>>
